<template>
  <div class="recommend-warp scrollTip">
    <!-- <AdvSwiper class="adv" :advList="ATP_YcTurnList" /> -->
    <div class="advBox">
      <div
        class="advItem"
        v-for="(item, index) in gridAdvList"
        :key="index"
        @click="jumpAdd(item)"
      >
        <div class="advImgBox">
          <ImgDecypt :src="item.pictureUrl" :key="item.pictureUrl" />
        </div>
        <div class="advTitle">{{ item.name }}</div>
      </div>
    </div>
    <!-- 人气粉丝团 -->
    <div class="recommend-content" v-if="recommendFGs.length > 0">
      <div class="fanGroup">
        <h2>人气粉丝团</h2>
        <swiper
          ref="videoSwiper"
          :options="swiperOptions"
          class="fanGroup-swiper"
          v-if="recommendFGs.length > 0"
        >
          <swiper-slide
            v-for="(item, index) in recommendFGs"
            :key="index"
            class="fanGroup-swiper-slide"
          >
            <div class="fanGroup-content" @click="jumpBlogger(item)">
              <div class="fanGroup-name">{{ item.name }}</div>
              <div class="fanGroup-avatar">
                <ImgDecypt :src="item.avatar" :round="true" />
              </div>
              <div class="fanGroup-info">粉丝数:{{ item.members }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <!-- 线 -->
    <div class="line"></div>
    <!-- tab -->
    <van-tabs
      active="activeName"
      class="recommend-tab"
      color="#f21313"
      title-inactive-color="#777777"
      title-active-color="#f21313"
      @change="change"
    >
      <van-tab title="最新" name="new">
        <RecommentTabInfo type="new" key="new" v-if="activeName == 'new'" />
      </van-tab>
      <van-tab title="热卖" name="hit">
        <RecommentTabInfo type="income" key="hit" v-if="activeName == 'hit'" />
      </van-tab>
      <van-tab title="热播" name="hot">
        <RecommentTabInfo type="watch" key="watch" v-if="activeName == 'hot'" />
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
// import AdvSwiper from "@/components/AdvSwiper/index.vue";
import RecommentTabInfo from "@/views/on_demand/recommend/recomment_tabInfo.vue";
import { getAdItem, AdType } from "@/utils/getAdv.js";
import { findFansGroup } from "@/api/on_demand.js";
import { jumpAdv } from "@/utils/getAdv.js";
export default {
  name: "recommend",
  components: {
    // AdvSwiper,
    swiper,
    swiperSlide,
    RecommentTabInfo,
  },
  data() {
    return {
      activeName: "new",
      recommendFGs: [],
      swiperOptions: {
        direction: "horizontal", // 横向切换选项
        slidesPerView: "auto", // 默认一个屏幕显示几张图
        spaceBetween: 10, // 间距
      },
      ATP_YcTurnList: [], //广告
      gridAdvList: [],
    };
  },
  created() {
    this.ATP_YcTurnList = getAdItem(AdType.ATP_YcTurn);
    this.gridAdvList = getAdItem(AdType.onDemandAdv).slice(0, 10);
    this.getFanGroupData();
  },
  methods: {
    jumpAdd(item) {
      jumpAdv(item);
    },
    change(value) {
      this.activeName = value;
    },
    //获取粉丝团信息
    async getFanGroupData() {
      let req = {
        pageNum: 1,
        pageSize: 7,
        recommend: true,
      };
      let ret = await this.$Api(findFansGroup, req);
      if (ret.code == 200 && ret.data.fansGroup != null) {
        this.recommendFGs = ret.data.fansGroup;
      }
    },
    //跳转博主详情
    jumpBlogger(bloggerInfo) {
      let id = bloggerInfo.id;
      this.$router.push({
        path: "/bloggerDetails",
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.recommend-warp {
  height: calc(100vh - 148px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  .adv /deep/ .swiperSlide {
    height: 156.25px !important;
  }
}
.recommend-tab /deep/ .van-tabs__wrap {
  position: sticky;
  top: 0;
  z-index: 100;
}

.line {
  width: 100%;
  height: 4px;
  margin: 14.5px 0 0 0;

  background: $whiteNine;
}
.recommend-content {
  padding: 0 10px;
  .fanGroup {
    font-size: 16px;
    h2 {
      padding: 20px 0;
    }
    color: $blackFourteen;
    .fanGroup-swiper {
      width: 100%;
      height: 100%;
      .fanGroup-swiper-slide {
        background: url("../../../assets/png/fun_bg.png") no-repeat;
        background-size: 100%;
        width: 122px;
        height: 69px;
        border-radius: 7px;
        .fanGroup-content {
          padding: 5px;
          color: #fff;
          position: relative;
          .fanGroup-name {
            font-size: 11px;
            width: 74px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            padding-top: 15px;
            text-align: center;
          }
          .fanGroup-avatar {
            position: absolute;
            top: 4px;
            right: 5px;
            width: 40px;
            height: 40px;
            background: black;
            border-radius: 50%;
          }
          .fanGroup-info {
            margin-top: 15px;
            padding: 0 4px;
            font-size: 10px;
            width: 90px;
            height: 15px;
            background: rgba(255, 255, 255, 0.41);
            border-radius: 15px;
            line-height: 15px;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
}
.recommend-tab {
  /deep/ .van-tab {
    font-size: 16px;
    width: 60px;
    flex: unset;
  }
  /deep/ .van-tabs__line {
    bottom: 18px;
    width: 24px;
    height: 5px;
    border-radius: 12px;
  }
}
.advBox {
  padding: 12px 16px 0;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  // margin-top: -59px;
  grid-gap: 11px;
  .advItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    .advImgBox {
      width: 60px;
      height: 60px;
      border-radius: 12px;
      overflow: hidden;
    }
    .advTitle {
      margin-top: 4px;
      font-size: 12px;
      color: #333;
      width: 60px;
      overflow: hidden; //超出的文本隐藏
      text-overflow: ellipsis; //溢出用省略号显示
      white-space: nowrap; // 默认不换行；
      text-align: center;
    }
  }
}
</style>
